<template>
  <div class="countries-add animatedBox">
    <div class="container fluid">
      <form @submit.prevent="submitForm">
        <div class="card mb-2 pb-1">
          <h4 class="mainTitle lineBottom">Country Content</h4>
          <div class="main-contents">
            <fd-input
              class="mt-2 px-0"
              type="text"
              :label="`Country Name`"
              v-model="country.name"
              :required="true"
            />
          </div>
          <div class="text-right px-2 mt-4 mb-2">
            <button type="submit" class="btn main">Create</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { CountryModel } from "@/models";

export default {
  name: "countries-add",
  data() {
    return {
      country: {
        name: ""
      }
    };
  },
  methods: {
    async submitForm() {
      this.$store.commit("setIsLoading", true, { root: true });
      try {
        const request = await this.$store.dispatch(
          "manageCountries/createCountries",
          CountryModel.postCountryPayload(this.country)
        );

        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "New country created successfully."
        });

        this.$router.push({ path: "/manage-countries" });
        this.$store.commit("setIsLoading", false, { root: true });
        return;
      } catch (error) {
        this.$store.commit("setIsLoading", false, { root: true });
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "An unexpected error occured. Please try again later."
        });
        console.log(error);
        throw error;
      }
    }
  }
};
</script>

<style lang="scss">
.countries-add {
  form {
    @extend %formDesign;
  }
}
</style>